import React from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import Products from './components/Products';
import Services from './components/Services';
import About from './components/About';
import { ThemeProvider } from '@material-tailwind/react';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Maps from './components/Maps';
import Udang from './components/Udang';
import Video from './components/Video'
import Home from './components/Home';

function App() {
  return (
    <>
   
      <Home />
    </>
  );
}

export default App;
