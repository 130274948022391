import React, { useRef, useEffect, useState } from 'react';
import { FaMoneyBillWave, FaChartLine, FaChalkboardTeacher } from 'react-icons/fa';


const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
    }
};


const FadeInSection = ({ children }) => {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef();

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.unobserve(ref.current);
                }
            },
            { threshold: 0.1 }
        );

        observer.observe(ref.current);

        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <div
            ref={ref}
            className={`transition-opacity duration-1000 ${isVisible ? 'opacity-100 fade-in' : 'opacity-0'}`}
        >
            {children}
        </div>
    );
};


const generateBubbles = (count) => {
    const bubbles = [];
    for (let i = 0; i < count; i++) {
        const size = Math.random() * 30 + 10; 
        const left = Math.random() * 100; 
        const top = Math.random() * 100;
        bubbles.push(
            <div
                key={i}
                className="bubble"
                style={{
                    width: `${size}px`,
                    height: `${size}px`,
                    left: `${left}%`,
                    top: `${top}%`,
                    animationDelay: `${Math.random() * 10}s`,
                }}
            />
        );
    }
    return bubbles;
};

// Hero Section
const Hero = () => (
    <section className="relative bg-white text-black overflow-hidden">
        <div className="absolute top-0 left-0 w-full h-full bg-pattern">
            <div className="relative w-full h-full">
                {generateBubbles(30)}
            </div>
        </div>
        <header className="relative z-10 p-4">
    <div className="container mx-auto flex flex-col items-center sm:flex-row sm:justify-between">
        <div className="w-full flex justify-start sm:w-auto">
            <img src="assets/kopas.png" alt="Logo Kopas" className="h-10" />
        </div>
 


                <nav className="mt-2 flex flex-wrap justify-center space-x-4 sm:mt-0 sm:flex-row sm:space-x-4">
                    <button onClick={() => scrollToSection('hero')} className="text-black">Beranda</button>
                    <button onClick={() => scrollToSection('services')} className="text-black">Layanan</button>
                    <button onClick={() => scrollToSection('moment')} className="text-black">Moment</button>
                    <button onClick={() => scrollToSection('product')} className="text-black">Produk</button>
                    <button onClick={() => scrollToSection('contact')} className="text-black">Kontak</button>
                </nav>
            </div>
        </header>
        <div className="relative z-10 container mx-auto px-4 sm:px-8 py-8 flex items-center justify-center">
            <FadeInSection>
                <div className="text-center max-w-3xl mx-auto">
                    <h2 className="text-3xl sm:text-4xl lg:text-5xl font-bold leading-tight text-black">
                        Memberdayakan Petambak Untuk Masa Depan Yang Berkelanjutan
                    </h2>
                    <p className="mt-4 text-lg sm:text-xl lg:text-2xl text-gray-600">
                        Bergabunglah dengan kami dalam menciptakan kesejahteraan bagi petambak.
                    </p>
                    <div className="mt-8 rounded-sm">
                        <img src="/assets/banner.jpg" alt="Hero" className="inline-block max-w-full h-auto rounded-lg" />
                    </div>
                </div>
            </FadeInSection>
        </div>
        <div className="relative z-10 container mx-auto px-4 sm:px-8 py-8 flex items-center justify-center">

        </div>
    </section>
    
);
const Carousel = () => {
    const slides = [
      {
        id: 1,
        image: 'https://e.top4top.io/p_3175h1mpl1.jpg',
        alt: 'Slide 1',
        title: 'KOPERASI PETAMBAK ASRI SEJAHTERA',
        description: 'Menyediakan semua kebutuhan budidaya udang vanamey.. dari peralatan tambak, benur, pakan dan obat obatan.',
      },
      {
        id: 2,
        image: 'https://lobsteranywhere.com/wp-content/uploads/2024/01/Types-of-Shrimp-1536x1024.jpg',
        alt: 'Slide 2',
        title: 'EMPOWERING COMMUNITIES',
        description: 'Mendukung masyarakat untuk mencapai kesejahteraan melalui kolaborasi dan inovasi yang berkelanjutan.',
      },
      {
        id: 3,
        image: 'https://h.top4top.io/p_3175wh2in1.jpg',
        alt: 'Slide 3',
        title: 'SUSTAINABLE GROWTH',
        description: 'Berkomitmen untuk pertumbuhan yang berkelanjutan dengan memanfaatkan teknologi dan inovasi.',
      },
    ];
  
    const [currentSlide, setCurrentSlide] = useState(0);
  
    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
      }, 5000); // Slide otomatis setiap 5 detik
  
      return () => clearInterval(interval);
    }, [slides.length]);
  
    return (
      <div className="relative w-full h-screen md:h-[600px] overflow-hidden">
        <div className="h-full w-full flex items-center justify-center">
          {slides.map((slide, index) => (
            <div
              key={slide.id}
              className={`absolute inset-0 transition-transform duration-700 ease-in-out ${
                index === currentSlide ? 'translate-x-0' : 'translate-x-full'
              }`}
              style={{
                transform: `translateX(${(index - currentSlide) * 100}%)`,
              }}
            >
              <div className="relative w-full h-full">
                <img
                  src={slide.image}
                  alt={slide.alt}
                  className="w-full h-full object-cover"
                />
                <div className="absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-50 text-white p-4 text-center md:text-left md:items-start md:p-8">
                  <h2 className="text-2xl md:text-4xl font-bold mb-2 md:mb-4">{slide.title}</h2>
                  <p className="text-sm md:text-lg">{slide.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };
  
  
  
  const Home = () => {
    return (
      <header className="relative z-10 p-4">
        <div className="container mx-auto flex flex-col items-center sm:flex-row sm:justify-between">
          <div className="w-full flex justify-start sm:w-auto">
            <h1 className="text-black text-2xl font-bold">Kopas</h1>
          </div>
        </div>
        <Carousel />
      </header>
    );
  };

const VideoEmbedCard = ({ embedSrc, title, description, date, category }) => {
    const iframeRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    // Menggunakan Intersection Observer untuk memantau apakah video terlihat di layar
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsVisible(entry.isIntersecting);
            },
            { threshold: 0.5 }
        );

        if (iframeRef.current) {
            observer.observe(iframeRef.current);
        }

        return () => {
            if (iframeRef.current) {
                observer.unobserve(iframeRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (isVisible) {
            iframeRef.current.src += "&autoplay=1";
        } else {
            const newSrc = iframeRef.current.src.replace("&autoplay=1", "");
            iframeRef.current.src = newSrc;
        }
    }, [isVisible]);

    return (
        <section id="moment">

            <div className="bg-white shadow-md rounded-lg overflow-hidden">

                <div className="relative w-full" style={{ paddingBottom: '56.25%' }}>
                    <iframe
                        ref={iframeRef}
                        src={embedSrc}
                        className="absolute top-0 left-0 w-full h-full"
                        style={{ border: 'none', overflow: 'hidden' }}
                        scrolling="no"
                        frameBorder="0"
                        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                        allowFullScreen
                    ></iframe>
                </div>
                <div className="p-4">

                    <div className="text-xs text-purple-600 font-bold uppercase mb-1">{category}</div>
                    <span className="text-xs text-gray-400">{date}</span>
                    <h2 className="text-lg font-bold text-gray-800 mt-2 mb-2">{title}</h2>
                    <p className="text-sm text-gray-600">{description}</p>
                </div>
            </div>
        </section>
    );
};

const VideoGridLayout = () => {
    const cards = [
        {
            embedSrc: 'https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fweb.facebook.com%2Fwans.santos.5%2Fvideos%2F1274436343201752%2F%3Fidorvanity%3D243082317467666&show_text=false&width=560&t=0',
            title: 'Semangat Budidaya',
            description: 'Semangat budidaya, Mental Juara untuk hasil yang sempurna ( blok 2 wm )',
            date: ' Sep 2024',
            category: 'Facebook Video'
        },


    ];

    return (

        <div className="container mx-auto px-4 py-8">
            {/* Grid layout */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {cards.map((card, index) => (
                    <VideoEmbedCard
                        key={index}
                        embedSrc={card.embedSrc}
                        title={card.title}
                        description={card.description}
                        date={card.date}
                        category={card.category}
                    />
                ))}
            </div>
        </div>
    );
};

// Services Section
const Services = () => (
    <section id="services" className="py-12 bg-gray-100">
        <div className="container mx-auto px-4 text-center">
            <h3 className="text-4xl font-semibold mb-12 text-black-800">Layanan Kami</h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12">
                <FadeInSection>
                    <div className="p-8 bg-white shadow-lg rounded-lg transition-transform transform hover:scale-105 flex flex-col items-center text-center">
                        <FaMoneyBillWave className="w-16 h-16 mb-4 text-blue-500" />
                        <h4 className="text-2xl font-semibold mb-4 text-gray-700">Pembiayaan</h4>
                        <p className="text-gray-600">Kami menyediakan akses pembiayaan bagi petambak untuk meningkatkan produksi.</p>
                    </div>
                </FadeInSection>
                <FadeInSection>
                    <div className="p-8 bg-white shadow-lg rounded-lg transition-transform transform hover:scale-105 flex flex-col items-center text-center">
                        <FaChartLine className="w-16 h-16 mb-4 text-green-500" />
                        <h4 className="text-2xl font-semibold mb-4 text-gray-700">Pemasaran</h4>
                        <p className="text-gray-600">Membantu petambak memasarkan hasil panen ke pasar yang lebih luas.</p>
                    </div>
                </FadeInSection>
                <FadeInSection>
                    <div className="p-8 bg-white shadow-lg rounded-lg transition-transform transform hover:scale-105 flex flex-col items-center text-center">
                        <FaChalkboardTeacher className="w-16 h-16 mb-4 text-purple-500" />
                        <h4 className="text-2xl font-semibold mb-4 text-gray-700">Pendidikan & Pelatihan</h4>
                        <p className="text-gray-600">Kami memberikan pelatihan untuk meningkatkan keterampilan petambak.</p>
                    </div>
                </FadeInSection>
            </div>
        </div>
    </section>
);

const Card = ({ image, title, description, date, category }) => {
    return (


        <div className="bg-white shadow-md rounded-lg overflow-hidden">
            <img src={image} alt={title} className="w-full h-48 object-cover" />
            <div className="p-4">
                <div className="text-xs text-purple-600 font-bold uppercase mb-1">{category}</div>
                <span className="text-xs text-gray-400">{date}</span>
                <h2 className="text-lg font-bold text-gray-800 mt-2 mb-2">{title}</h2>
                <p className="text-sm text-gray-600">{description}</p>
            </div>
        </div>


    );
};

const GridLayout = () => {
    const cards = [
        {
            image: 'assets/dorprize.png',
            title: 'Kopas Doorprize',
            description: 'Acara pengundian doorprize kopas, sukses untuk semua',
            date: '13 Feb 2023',
            category: 'Doorprize'
        },
        {
            image: 'assets/bakti.jpg',
            title: 'Kopas Bakti Sosial',
            description: 'Kopas kegiatan bakti sosial dibidang pendidikan TK, SD & SMP blok 2 WM',
            date: '30 Sep 2023',
            category: 'Sosial'
        },
        {
            image: 'assets/GUDANG.jpg',
            title: 'Gudang Kopas',
            description: 'Gudang Koperasi Petambak Asri Sejahtera',
            date: '23 Jan 2023',
            category: 'Travel Tips'
        }
    ];

    return (
        <div className="container mx-auto px-4 py-8">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {cards.map((card, index) => (
                    <Card
                        key={index}
                        image={card.image}
                        title={card.title}
                        description={card.description}
                        date={card.date}
                        category={card.category}
                    />
                ))}
            </div>
        </div>
    );
};

// Product Section
const Product = () => (
    <section id="product" className="py-12">
        <div className="container mx-auto px-4">
            <h3 className="text-3xl font-bold mb-8 text-black text-center">Produk Kami</h3>
            <div className="grid grid-cols-2 sm:grid-cols-3 gap-6">
                <FadeInSection>
                    <div className="w-full bg-white rounded-lg shadow-lg overflow-hidden">
                        <img
                            src="https://scontent.ftkg1-1.fna.fbcdn.net/v/t39.30808-6/159630690_331614625272711_7294487922735057866_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=833d8c&_nc_ohc=Dp4yQQHmyVUQ7kNvgEW4tR_&_nc_zt=23&_nc_ht=scontent.ftkg1-1.fna&_nc_gid=AP2lPHBdIr5KCs07PvggCQu&oh=00_AYBSzD-KG6p8nYqc19B5RbIhkQhyfRntUJWCTs7RVGNMLg&oe=66E31DBB"
                            alt="Pakan Udang 1"
                            className="w-full h-full object-cover"
                        />
                    </div>
                </FadeInSection>
                <FadeInSection>
                    <div className="w-full bg-white rounded-lg shadow-lg overflow-hidden">
                        <img
                            src="https://api.minapoli.com/media/products/p/medium_086d5e547cafcded639ab7257d09b156.png"
                            alt="Pakan Udang 2"
                            className="w-full h-full object-cover"
                        />
                    </div>
                </FadeInSection>
                <FadeInSection>
                    <div className="w-full bg-white rounded-lg shadow-lg overflow-hidden">
                        <img
                            src="assets/bestari.jpg"
                            alt="Pakan Udang 3"
                            className="w-full h-full object-cover"
                        />
                    </div>
                </FadeInSection>
                <FadeInSection>
                    <div className="w-full bg-white rounded-lg shadow-lg overflow-hidden">
                        <img
                            src="https://scontent.ftkg1-1.fna.fbcdn.net/v/t1.6435-9/120465683_158232119277630_3804745128719568283_n.jpg?_nc_cat=109&ccb=1-7&_nc_sid=127cfc&_nc_ohc=146cnSImROMQ7kNvgF1XkwK&_nc_ht=scontent.ftkg1-1.fna&_nc_gid=AkZKEdT_OyhhJT5aemGebVG&oh=00_AYAFUEFaqCWnx6NJIJYSJbwQvUjHXP-VpwONKI5OzbWN4A&oe=6704C293"
                            alt="Pakan Udang 4"
                            className="w-full h-full object-cover"
                        />
                    </div>
                </FadeInSection>
                <FadeInSection>
                    <div className="w-full bg-white rounded-lg shadow-lg overflow-hidden">
                        <img
                            src="assets/obatan.jpg"
                            alt="Pakan Udang 4"
                            className="w-full h-full object-cover"
                        />
                    </div>
                </FadeInSection>
                <FadeInSection>
                    <div className="w-full bg-white rounded-lg shadow-lg overflow-hidden">
                        <img
                            src="assets/dunia-udang.jpg"
                            alt="Pakan Udang 4"
                            className="w-full h-full object-cover"
                        />
                    </div>
                </FadeInSection>

            </div>
        </div>
    </section>
);

// Footer Section
const Footer = () => (
    <section id="contact">
    <footer className="bg-black text-white py-12">
        <div className="container mx-auto px-4 text-center">
            <h2 className="text-5xl font-bold mb-4">Kopas Sejahtera</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 items-center justify-center mb-8">
                <div className="text-2xl font-bold">
                    <a href="mailto:kopassejahtera@gmail.com" className="hover:underline">
                        kopassejahtera@gmail.com
                    </a>
                </div>
                <div>
                    <a href='https://wa.me/6285840287221' className="border border-white rounded-full py-2 px-8 text-white font-semibold hover:bg-white hover:text-black transition">
                        Hubungi Kami
                    </a>
                </div>
                <div className="text-2xl font-bold">
                    <a href="tel:+6285840287221" className="hover:underline">
                        +62 858-4028-7221
                    </a>
                    <p className="text-sm">Irwan Santoso</p>
                </div>
            </div>
            <div className="flex justify-center">
                <a href="#top" className="text-white hover:text-gray-400 transition">
                    <div className="flex flex-col items-center">
                        <span className="text-xs">TOP</span>
                        <svg
                            className="w-6 h-6 mt-2 animate-bounce"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 10l7-7m0 0l7 7m-7-7v18"
                            />
                        </svg>
                    </div>
                </a>
            </div>
        </div>
    </footer>
    </section>
);

// App Component
const App = () => (
    <>
        <Hero />
        <Carousel />
        <Services />
        <VideoGridLayout />
        <GridLayout />
        <Product />
        <Footer />
    </>
);

export default App;
